import React, {useEffect, useState} from 'react'
import {KTSVG, KTCardBody, KTCard} from '../../_metronic/helpers'
import dummyCard from '../../assets/newDummyCard.JPG'
import './style.css'
import {getCards} from '../services/admin-services/manage-cards'
import {Link} from 'react-router-dom'
import {Loading} from '../loading/Loading'

const Cards = () => {
  useEffect(() => {
    getAllCards()
  }, [])
  const [loadingState, setLoadingState] = useState(false)
  const [cardsFound, setCardsFound] = useState(true)
  const [allCards, setAllCards] = useState([])
  const getAllCards = async () => {
    try {
      setLoadingState(true)
      const result = await getCards(accessToken)
      // console.log('cards', result)
      if (result.status === true) {
        setLoadingState(false)
        setCardsFound(true)
        setAllCards(result.cards.data)
      }
    } catch (err) {
      setLoadingState(false)
      setCardsFound(false)
      console.log('getAllCards err', err)
      // setErrorModelText(err.response.data.message)
      // setErrorModel(true);
    }
  }
  const userData = localStorage.getItem('userData')
  const transformedData = JSON.parse(userData || '')
  const {accessToken} = transformedData
  console.log(allCards)
  return (
    <>
      {/* Header Start */}
      <KTCard className=' mb-20 min-h-600px'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>Cards</div>
        </div>
        {/* Header End */}
        {/* Table Start */}
        <KTCardBody className='py-4 mb-4 scroll_data'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer '>
              <tbody className='text-gray-600 fw-bold'>
                {allCards?.map((item, index) => (
                  <tr key={index}>
                    <Link to={`/card/${item.id}`}>
                      {item.banner !== '' &&
                      item.banner !== null &&
                      item.banner?.type == 'image' ? (
                        <td className=' min-w-100px'>
                          <img
                            src={item.banner?.full_path + 'thumb/' + item.banner?.file_name}
                            alt=''
                            className='img-fluid w-150px h-70px '
                          />
                        </td>
                      ) : (
                        <td className=' min-w-100px'>
                          <img
                            src={dummyCard}
                            alt='not found'
                            className='img-fluid w-150px h-70px '
                          />
                        </td>
                      )}
                    </Link>
                    <Link to={`/card/${item.id}`} state={{item: item}}>
                      <td className='text-center min-w-100px' style={{cursor: 'pointer'}}>
                        {item.first_name == '' && item.last_name == ''
                          ? 'No name'
                          : `${item.first_name} ${item.last_name}`}
                        {/* {`${item.first_name} ${item.last_name}`} */}
                        {console.log(item.first_name)}
                      </td>
                    </Link>
                  </tr>
                ))}
              </tbody>
            </table>
            {loadingState && <Loading />}
          </div>
        </KTCardBody>
      </KTCard>
      {/* Table End */}
    </>
  )
}

export default Cards
