import React, {useState, useEffect} from 'react'
import {KTCardBody, KTCard} from '../../_metronic/helpers'
import {useParams, Link, useLocation, Navigate, useNavigate} from 'react-router-dom'
import {getSingleCard} from '../services/admin-services/manage-cards'
import {BsArrowLeft} from 'react-icons/bs'
import {Loading} from '../loading/Loading'
import dummyCard from '../../assets/newDummyCard.JPG'
import newDummyCard from '../../assets/newDummyCard.JPG'
import RoundDummyCard from '../../assets/roundDummyImage.JPG'
import card1 from '../../assets/card1.png'
import card2 from '../../assets/card2.png'
import card3 from '../../assets/card3.png'
import '../style.css'
import {MdArrowBackIosNew} from 'react-icons/md'
const ViewCard = () => {
  const [card, setCard] = useState([])
  const [themeColor, setThemeColor] = useState('')
  const [loadingState, setLoadingState] = useState(false)
  const userData = localStorage.getItem('userData')
  const transformedData = JSON.parse(userData || '')
  const {accessToken} = transformedData
  const {card_id} = useParams()
  const location = useLocation()
  const Navigate = useNavigate()
  const [locationState, setLocationState] = useState({item: ''})
  useEffect(() => {
    // if (location.state) {
    //   let _state = location.state
    //   setLocationState(_state)
    // } else {
    if (location.item?.theme_color) {
      let color = JSON.parse(location.item?.theme_color)
      setThemeColor(color.primary_color)
    }
    async function getUser() {
      try {
        setLoadingState(true)
        const result = await getSingleCard(card_id, accessToken)
        if (result.status === true) {
          setLoadingState(false)
          setCard(result.card)
          if (result.card?.theme_color) {
            let color = JSON.parse(result.card?.theme_color)
            setThemeColor(color.primary_color)
          }
        }
      } catch (err) {
        setLoadingState(true)
        console.log('user err', err)
      }
    }
    getUser()
    // }
    // }, [location])
  }, [card_id])
  //
  console.log(card)
  return (
    <>
      <span
        className='px-2 py-1'
        style={{
          position: 'relative',
          bottom: '25px',
          cursor: 'pointer',
          backgroundColor: '#bae409',
          borderRadius: '5px',
        }}
        onClick={() => Navigate(-1)}
      >
        <MdArrowBackIosNew size={12} style={{position: 'relative', bottom: '1px'}} />
        Back
      </span>

      <div className='row position-relative'>
        {!loadingState ? (
          <>
            <div className='col-md-4 ' style={{position: 'sticky', top: 0, height: '100%'}}>
              <KTCard className=' mb-5 pb-5 px-0'>
                {!loadingState && (
                  <KTCardBody className='py-2 mb-4 px-2 '>
                    <h5 className='text-center  p-4 rounded' style={{backgroundColor: ' #BAE409'}}>
                      {/* <Link
                        to={
                          {pathname: '/manage-cards', state: {prevPath: location.pathname}} ??
                          '/manage-cards'
                        }
                      > */}
                      &nbsp;
                      {/* </Link> */}
                      {locationState.item.title ?? card.title} &nbsp;Details
                    </h5>
                    <div className='col-12 text-center'>
                      <h5>Card Logo</h5>
                      <div className='  my-3 position-relative'>
                        <img
                          src={
                            locationState?.item.logo?.full_path + locationState.item.logo?.file_name
                              ? locationState?.item?.logo?.full_path +
                                'thumb/' +
                                locationState?.item?.logo?.file_name
                              : card.logo?.full_path + card.logo?.file_name
                              ? card.logo?.full_path + 'thumb/' + card.logo?.file_name
                              : RoundDummyCard
                          }
                          alt=''
                          className='img-fluid'
                          style={{borderRadius: '70%', width: '170px', height: '170px'}}
                        />
                        {/* {locationState?.item.logo?.full_path +
                        locationState.item.logo?.file_name ? null : card.logo?.full_path +
                          card.logo?.file_name ? null : (
                          <p className='position-absolute ' style={{top: '45%', left: '38%'}}>
                            Not Available
                          </p>
                        )} */}
                      </div>
                    </div>
                    <div className='col-12 text-center'>
                      <h5 className=''>Card QR code</h5>
                      <div className='  my-3 position-relative'>
                        {locationState.item.qr_code !== '' &&
                        locationState.item.qr_code !== null ? (
                          <img
                            src={locationState.item.qr_code ?? card.qr_code}
                            alt=''
                            className='img-fluid w-150px rounded  h-150px'
                          />
                        ) : (
                          <img
                            src={newDummyCard}
                            alt=''
                            className='img-fluid   w-200px rounded  h-130px'
                          />
                        )}
                        {/* {locationState.item.qr_code !== '' &&
                        locationState.item.qr_code !== null &&
                        locationState.item.qr_code !== undefined ? null : (
                          <p className='position-absolute ' style={{top: '45%', left: '38%'}}>
                            Not Available
                          </p>
                        )} */}
                      </div>
                    </div>

                    <div className='col-12 text-center'>
                      <h5>Card Banner</h5>
                      <div className='  my-3 position-relative'>
                        {locationState?.item?.banner?.type == 'video' ||
                        card.banner?.type == 'video' ? (
                          <video
                            className='img-fluid w-150px h-100px rounded'
                            width='125px'
                            height='100%'
                            controls
                            src={
                              locationState.item.banner?.full_path +
                              locationState.item.banner?.file_name
                                ? locationState.item.banner?.full_path +
                                  'thumb/' +
                                  locationState.item.banner?.file_name
                                : card.banner?.full_path + card.banner?.file_name
                                ? card.banner?.full_path + 'thumb/' + card.banner?.file_name
                                : newDummyCard
                            }
                          />
                        ) : (
                          <img
                            src={
                              locationState.item.banner?.full_path +
                              locationState.item.banner?.file_name
                                ? locationState.item.banner?.full_path +
                                  'thumb/' +
                                  locationState.item.banner?.file_name
                                : card.banner?.full_path + card.banner?.file_name
                                ? card.banner?.full_path + 'thumb/' + card.banner?.file_name
                                : newDummyCard
                            }
                            alt=''
                            className='img-fluid w-200px rounded  h-130px'
                          />
                        )}

                        {/* {locationState.item.banner?.full_path +
                        locationState.item.banner?.file_name ? null : card.banner?.full_path +
                          card.banner?.file_name ? null : (
                          <img
                            src={newDummyCard}
                            alt=''
                            className='img-fluid w-200px rounded  h-150px'
                          />
                        )} */}

                        {/* ) : (
                  <img src={dummyCard} alt='not found' className='img-fluid w-150px h-70px ' />
                )} */}
                      </div>
                    </div>
                    <div className='col-12 text-center'>
                      <h5>Card Theme</h5>
                      <div className='  my-3'>
                        {locationState?.item?.theme_image !== '' &&
                        locationState?.item?.theme_image !== null &&
                        locationState?.item?.theme_image !== undefined ? (
                          <img
                            src={
                              locationState.item.theme_image?.full_path +
                              locationState.item.theme_image?.file_name
                                ? locationState.item.theme_image?.full_path +
                                  locationState.item.theme_image?.file_name
                                : dummyCard
                            }
                            alt=''
                            className='img-fluid w-200px rounded  h-140px'
                          />
                        ) : card?.theme_image !== '' &&
                          card.theme_image !== null &&
                          card.theme_image !== undefined ? (
                          <img
                            src={
                              card.theme_image?.full_path + card.theme_image?.file_name
                                ? card.theme_image?.full_path + card.theme_image?.file_name
                                : dummyCard
                            }
                            alt=''
                            className='img-fluid w-200px rounded  h-140px'
                          />
                        ) : (
                          <>
                            {locationState.item.theme_style == 1 || card?.theme_style == 1 ? (
                              <img
                                src={card1}
                                className='img-fluid w-200px h-140px'
                                alt=''
                                style={{border: '.5px solid black'}}
                              />
                            ) : locationState.item.theme_style == 1 || card?.theme_style == 2 ? (
                              <img
                                src={card2}
                                className='img-fluid w-200px h-140px'
                                alt=''
                                style={{border: '.5px solid black'}}
                              />
                            ) : locationState.item.theme_style == 1 || card?.theme_style == 3 ? (
                              <img
                                src={card3}
                                className='img-fluid w-200px h-140px'
                                alt=''
                                style={{border: '.5px solid black'}}
                              />
                            ) : (
                              <img
                                src={dummyCard}
                                className='img-fluid w-200px h-140px'
                                alt=''
                                style={{border: '.5px solid black'}}
                              />
                            )}
                          </>
                        )}

                        {/* ) : (
                  <img src={dummyCard} alt='not found' className='img-fluid w-150px h-70px ' />
                )} */}
                      </div>
                    </div>
                    {/* <div className='col-12 text-center'>
                      <h5>Theme Color</h5>
                      <div
                        className=' btn btn-icon btn-circle me-2'
                        style={{
                          backgroundColor: themeColor,
                          // border: `2px solid black`,
                        }}
                      ></div>
                    </div> */}
                  </KTCardBody>
                )}

                {loadingState && <Loading />}
              </KTCard>
            </div>
            <div className='col-8'>
              <div className='row'>
                <div className='col-12'>
                  <KTCard className=' mb-5 pb-5'>
                    <h5
                      className='text-start  p-4 m-2 rounded'
                      style={{backgroundColor: ' #BAE409'}}
                    >
                      Headline
                    </h5>
                    <KTCardBody className='pt-0 ps-2 pb-1 mb-4'>
                      <td className='rounded p-3'>
                        {locationState.item.headline !== '' &&
                        locationState.item.headline !== null &&
                        locationState.item.headline !== undefined
                          ? locationState.item.headline
                          : card.headline !== '' &&
                            card.headline !== null &&
                            card.headline !== undefined
                          ? card.headline
                          : '---'}
                      </td>
                    </KTCardBody>
                  </KTCard>
                </div>
                <div className='col-6'>
                  <KTCard className=' mb-5 pb-5 px-0'>
                    <h5
                      className='text-center  p-4 m-2 rounded'
                      style={{backgroundColor: ' #BAE409'}}
                    >
                      Personal Detail
                    </h5>
                    {!loadingState && (
                      <KTCardBody className='py-2 px-2 scroll_data'>
                        <div class='table-responsive px-4'>
                          <table class='table table-row-dashed table-row-gray-300 gy-3 '>
                            <tbody>
                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Prefix</th>
                                <td>
                                  {locationState.item.card_meta?.prefix !== '' &&
                                  locationState.item.card_meta?.prefix !== undefined &&
                                  locationState.item.card_meta?.prefix !== null
                                    ? locationState.item.card_meta?.prefix
                                    : card.card_meta?.prefix !== '' &&
                                      card.card_meta?.prefix !== undefined &&
                                      card.card_meta?.prefix !== null
                                    ? card.card_meta?.prefix
                                    : '---'}
                                </td>
                              </tr>
                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Suffix</th>
                                <td>
                                  {locationState.item.card_meta?.suffix !== '' &&
                                  locationState.item.card_meta?.suffix !== null &&
                                  locationState.item.card_meta?.suffix !== undefined
                                    ? locationState.item.card_meta?.suffix
                                    : card.card_meta?.suffix !== '' &&
                                      card.card_meta?.suffix !== null &&
                                      card.card_meta?.suffix !== undefined
                                    ? card.card_meta?.suffix
                                    : '---'}
                                </td>
                              </tr>
                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Title</th>
                                <td>
                                  {locationState.item.title !== null &&
                                  locationState.item.title !== '' &&
                                  locationState.item.title !== undefined
                                    ? locationState.item.title
                                    : card.title !== null &&
                                      card.title !== '' &&
                                      card.title !== undefined
                                    ? card.title
                                    : '---'}
                                </td>
                              </tr>

                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                                  First Name
                                </th>
                                <td>
                                  {locationState.item.first_name !== '' &&
                                  locationState.item.first_name !== null &&
                                  locationState.item.first_name !== undefined
                                    ? locationState.item.first_name
                                    : card.first_name !== '' &&
                                      card.first_name !== null &&
                                      card.first_name !== undefined
                                    ? card.first_name
                                    : '---'}
                                </td>
                              </tr>
                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                                  Maiden Name
                                </th>
                                <td>
                                  {locationState.item.card_meta?.maiden_name !== '' &&
                                  locationState.item.card_meta?.maiden_name !== null &&
                                  locationState.item.card_meta?.maiden_name !== undefined
                                    ? locationState.item.card_meta?.maiden_name
                                    : card.card_meta?.maiden_name !== '' &&
                                      card.card_meta?.maiden_name !== null &&
                                      card.card_meta?.maiden_name !== undefined
                                    ? card.card_meta?.maiden_name
                                    : '---'}
                                </td>
                              </tr>
                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                                  Middle Name
                                </th>
                                <td>
                                  {locationState.item.card_meta?.middle_name !== null &&
                                  locationState.item.card_meta?.middle_name !== undefined &&
                                  locationState.item.card_meta?.middle_name !== ''
                                    ? locationState.item.card_meta?.middle_name
                                    : card.card_meta?.middle_name !== null &&
                                      card.card_meta?.middle_name !== undefined &&
                                      card.card_meta?.middle_name !== ''
                                    ? card.card_meta?.middle_name
                                    : '---'}
                                </td>
                              </tr>
                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                                  Last Name
                                </th>
                                <td>
                                  {locationState.item.last_name !== null &&
                                  locationState.item.last_name !== undefined &&
                                  locationState.item.last_name !== ''
                                    ? locationState.item.last_name
                                    : card.last_name !== null &&
                                      card.last_name !== undefined &&
                                      card.last_name !== ''
                                    ? card.last_name
                                    : '---'}
                                </td>
                              </tr>
                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                                  preferred Name
                                </th>
                                <td>
                                  {locationState.item.card_meta?.preferred_name !== null &&
                                  locationState.item.card_meta?.preferred_name !== undefined &&
                                  locationState.item.card_meta?.preferred_name !== ''
                                    ? locationState.item.card_meta?.preferred_name
                                    : card.card_meta?.preferred_name !== null &&
                                      card.card_meta?.preferred_name !== undefined &&
                                      card.card_meta?.preferred_name !== ''
                                    ? card.card_meta?.preferred_name
                                    : '---'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </KTCardBody>
                    )}
                    {loadingState && <Loading />}
                  </KTCard>
                </div>
                <div className='col-6'>
                  <KTCard className=' mb-5 pb-5 px-0'>
                    <h5
                      className='text-center m-2 p-4 rounded'
                      style={{backgroundColor: ' #BAE409'}}
                    >
                      Company Detail
                    </h5>
                    {!loadingState && (
                      <KTCardBody className='py-2 px-2 scroll_data'>
                        <div class='table-responsive px-4'>
                          <table class='table table-row-dashed table-row-gray-300 gy-3 '>
                            <tbody>
                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                                  Accreditation
                                </th>
                                <td>
                                  {locationState.item.card_meta?.accreditation !== '' &&
                                  locationState.item.card_meta?.accreditation !== undefined &&
                                  locationState.item.card_meta?.accreditation !== null
                                    ? locationState.item.card_meta?.accreditation
                                    : card.card_meta?.accreditation !== '' &&
                                      card.card_meta?.accreditation !== null &&
                                      card.card_meta?.accreditation !== undefined
                                    ? card.card_meta?.accreditation
                                    : '---'}
                                </td>
                              </tr>
                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                                  Company
                                </th>
                                <td>
                                  {locationState.item.company !== null &&
                                  locationState.item.company !== '' &&
                                  locationState.item.company !== undefined
                                    ? locationState.item.company
                                    : card.company !== null &&
                                      card.company !== '' &&
                                      card.company !== undefined
                                    ? card.company
                                    : '---'}
                                </td>
                              </tr>
                              <tr>
                                <th className='min-w-150px fw-bolder  fs-6 text-gray-800'>
                                  Department
                                </th>
                                <td>
                                  {locationState.item.department !== '' &&
                                  locationState.item.department !== null &&
                                  locationState.item.department !== undefined
                                    ? locationState.item.department
                                    : card.department !== '' &&
                                      card.department !== null &&
                                      card.department !== undefined
                                    ? card.department
                                    : '---'}
                                </td>
                              </tr>

                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Theme</th>
                                <td>
                                  {locationState.item.theme_style !== '' &&
                                  locationState.item.theme_style !== null &&
                                  locationState.item.theme_style !== undefined
                                    ? locationState.item.theme_style
                                    : card?.theme_style !== '' &&
                                      card?.theme_style !== null &&
                                      card?.theme_style !== undefined
                                    ? card?.theme_style
                                    : '---'}
                                </td>
                              </tr>
                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                                  Pronouns
                                </th>
                                <td>
                                  {locationState.item.card_meta?.pronouns !== '' &&
                                  locationState.item.card_meta?.pronouns !== undefined &&
                                  locationState.item.card_meta?.pronouns !== null
                                    ? locationState.item.card_meta?.pronouns
                                    : card.card_meta?.pronouns !== '' &&
                                      card.card_meta?.pronouns !== undefined &&
                                      card.card_meta?.pronouns !== null
                                    ? card.card_meta?.pronouns
                                    : '---'}
                                </td>
                              </tr>

                              <tr>
                                <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                                  Card Status
                                </th>
                                <td>
                                  {locationState.item.status ?? card.status === 'active' ? (
                                    <span className='badge badge-success'>Active</span>
                                  ) : locationState.item.status ?? card.status === 'pending' ? (
                                    <span className='badge badge-warning me-1 mb-1'>Pending</span>
                                  ) : locationState.item.status ?? card.status === 'disabled' ? (
                                    <span className='badge badge-secondary me-1'>Inactive</span>
                                  ) : locationState.item.status ?? card.status === 'disabled' ? (
                                    <span className='badge badge-danger'>Deleted</span>
                                  ) : null}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </KTCardBody>
                    )}
                    {loadingState && <Loading />}
                  </KTCard>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <KTCard className=' mb-5  px-0'>
                    <KTCardBody className='py-2 px-2'>
                      <h5
                        className='text-center  p-4 rounded'
                        style={{backgroundColor: ' #BAE409'}}
                      >
                        Locations
                      </h5>
                      <div
                        class='table-responsive px-4'
                        style={{maxHeight: '250px', overFlowY: 'scroll'}}
                      >
                        <table class='table table-row-dashed table-row-gray-300 gy-3 mb-0'>
                          <tbody>
                            {card.locations?.length > 0 ? (
                              <>
                                <tr>
                                  <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                                    Country
                                  </th>
                                  <th className='min-w-150px fw-bolder fs-6 text-gray-800'>
                                    Province
                                  </th>
                                  <th className='min-w-150px fw-bolder fs-6 text-gray-800'>City</th>
                                </tr>

                                {locationState.locations ??
                                  card.locations?.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        {item.country !== '' &&
                                        item.country !== null &&
                                        item.country !== undefined
                                          ? item.country
                                          : '---'}
                                      </td>
                                      <td>
                                        {item.province !== '' &&
                                        item.province !== null &&
                                        item.province !== undefined
                                          ? item.province
                                          : '---'}
                                      </td>
                                      <td>
                                        {item.city !== '' &&
                                        item.city !== null &&
                                        item.city !== undefined
                                          ? item.city
                                          : '---'}
                                      </td>
                                    </tr>
                                  ))}
                              </>
                            ) : (
                              <div className='h-100'>
                                <p className='text-center mb-0'>No location</p>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {loadingState && <Loading />}
                    </KTCardBody>
                  </KTCard>
                </div>
                <div className='col-6'>
                  {' '}
                  <KTCard className=' mb-5 pb-5 px-0'>
                    <h5
                      className='text-center m-2  p-4 rounded'
                      style={{backgroundColor: ' #BAE409'}}
                    >
                      Social Links
                    </h5>
                    <KTCardBody className='py-2 px-2 '>
                      <div
                        class='table-responsive px-4'
                        style={{minHeight: '110px', maxHeight: '110px', overFlowY: 'scroll'}}
                      >
                        {/* <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Prefix</th> */}
                        {locationState.item.cards_items?.length > 0 ? (
                          locationState.item.cards_items?.map((item, i) => (
                            <div key={i} className='first-division mb-3 col-md-12 col-7 mt-1'>
                              <a target='_blank' href={item.value}>
                                {item.value ?? '---'}
                              </a>
                            </div>
                          ))
                        ) : card?.cards_items?.length > 0 ? (
                          card?.cards_items.map((item, i) => (
                            <div key={i} className='first-division mb-3 col-md-12 col-7 mt-1'>
                              <a target='_blank' href={item.value}>
                                {item.value ?? '---'}
                              </a>
                            </div>
                          ))
                        ) : (
                          <div
                            className='d-flex justify-content-center align-items-center '
                            style={{height: '110px'}}
                          >
                            <p className='text-center mb-0'>No social Links</p>
                          </div>
                        )}
                      </div>
                    </KTCardBody>
                  </KTCard>
                </div>
                <div className='col-6'>
                  {' '}
                  <KTCard className=' mb-5 pb-5 px-0'>
                    <h5
                      className='text-center  p-4 m-2 rounded'
                      style={{backgroundColor: ' #BAE409'}}
                    >
                      Contact Detail
                    </h5>
                    <KTCardBody className='py-2 px-2 '>
                      <div
                        class='table px-4'
                        style={{minHeight: '97px', maxHeight: '97px', overFlowY: 'scroll'}}
                      >
                        {locationState.item.user?.email ?? (
                          <>
                            {locationState.item.user?.email !== '' &&
                            locationState.item.user?.email !== null &&
                            locationState.item.user?.email !== undefined ? (
                              <div className='row'>
                                {/* <div className='col-3 fw-bolder fs-6 text-gray-800'>Email</div> */}
                                <div
                                  className='col-12 text-start cursor-pointer text-primary'
                                  onClick={() =>
                                    (window.location.href = 'mailto:' + card.user?.email)
                                  }
                                >
                                  {locationState.item.user?.email !== '' &&
                                  locationState.item.user?.email !== null &&
                                  locationState.item.user?.email !== undefined
                                    ? locationState.item.user?.email
                                    : 'No email'}
                                </div>
                              </div>
                            ) : card.user?.email !== '' &&
                              card.user?.email !== undefined &&
                              card.user?.email !== null ? (
                              <div className='row'>
                                {/* <div className='col-3 fw-bolder fs-6 text-gray-800'>Email</div> */}
                                <div className='col-12 '>
                                  <span
                                    className='ms-auto cursor-pointer text-primary'
                                    onClick={() =>
                                      (window.location.href = 'mailto:' + card.user?.email)
                                    }
                                  >
                                    {card.user?.email !== '' &&
                                    card.user?.email !== null &&
                                    card.user?.email !== undefined
                                      ? card.user?.email
                                      : '---'}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div
                                className='d-flex justify-content-center align-items-center '
                                style={{height: '97px'}}
                              >
                                <p className='text-center mb-0'>--</p>
                              </div>
                            )}
                          </>
                        )}
                        {/* <tr>
                              <th className='min-w-150px fw-bolder fs-6 text-gray-800'>Phone</th>
                              <td>
                                {locationState.item.card_meta?.suffix ?? card.card_meta?.suffix}
                              </td>
                            </tr> */}
                      </div>
                      {loadingState && <Loading />}
                    </KTCardBody>
                  </KTCard>
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  {' '}
                  <KTCard className=' mb-5 py-5'>
                    <KTCardBody className='py-2 '>
                      <div className='row'>
                        <div className=' fw-bolder fs-6 text-gray-800 col-sm-3'>Status</div>
                        <div className='col-sm-9 text-end'>
                          {locationState.item.status ?? card.status === 'active' ? (
                            <span className='badge badge-success'>Active</span>
                          ) : locationState.item.status ?? card.status === 'pending' ? (
                            <span className='badge badge-warning me-1 mb-1'>Pending</span>
                          ) : locationState.item.status ?? card.status === 'disabled' ? (
                            <span className='badge badge-secondary me-1'>Inactive</span>
                          ) : locationState.item.status ?? card.status === 'deleted' ? (
                            <span className='badge badge-danger '>Deleted</span>
                          ) : null}
                        </div>
                      </div>
                    </KTCardBody>
                  </KTCard>{' '}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  )
}

export default ViewCard
{
}
