import React, {useState, useEffect} from 'react'
import {Link, useParams, useLocation, Navigate, useNavigate} from 'react-router-dom'
import {getSingleUser} from '../services/admin-services/manage-users'
import logoImg from '../../assets/iconLogo.png'
import {BsArrowLeft} from 'react-icons/bs'
import {BsArrowRight} from 'react-icons/bs'
import {Loading} from '../loading/Loading'
import dummyCard from '../../assets/newDummyCard.JPG'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import {MdArrowBackIosNew} from 'react-icons/md'
const ViewUser = () => {
  const [singleUser, setSingleUser] = useState([])
  const [openModel, setOpenModel] = useState(false)
  const [loadingState, setLoadingState] = useState(false)
  const userData = localStorage.getItem('userData')
  const transformedData = JSON.parse(userData || '')
  const {accessToken} = transformedData
  const {id} = useParams()
  const Navigate = useNavigate()
  const location = useLocation()
  const [locationState, setLocationState] = useState({item: ''})

  useEffect(() => {
    // if (location.state) {
    //   let _state = location.state
    //   setLocationState(_state)
    // } else {

    async function getUser() {
      try {
        setLoadingState(true)
        const result = await getSingleUser(id, accessToken)
        console.log('aa', result)
        if (result.status === true) {
          setLoadingState(false)
          setSingleUser(result.user)
        }
      } catch (err) {
        setLoadingState(false)
        console.log('user err', err)
      }
    }
    getUser()
    // }
  }, [id])
  // }, [location])

  console.log(singleUser)
  ///////////Date Formatter///////////
  const dateFormateHandler = (createdAt) => {
    let today = new Date(createdAt)

    let date = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      // hour: '2-digit',
      // minute: '2-digit',
      // second: '2-digit',
    }).format(today)
    return date
  }

  return (
    <>
      <span
        className='px-2 py-1'
        style={{
          position: 'relative',
          bottom: '25px',
          cursor: 'pointer',
          backgroundColor: '#bae409',
          borderRadius: '5px',
        }}
        onClick={() => Navigate(-1)}
      >
        <MdArrowBackIosNew size={12} />
        Back
      </span>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header d-flex justify-content-between cursor-pointer'>
          <div className='card-title m-0 '>
            <h3 className='fw-bolder m-0'>
              {/* <Link to='/manage-users'> */}
              &nbsp; Profile Details
            </h3>
          </div>
          <div className='card-title  m-0'>
            <Link
              className='btn btn-primary '
              to={`/manage-users/${singleUser.id}`}
              onClick={() => setOpenModel(true)}
            >
              View Cards
            </Link>
          </div>
        </div>
        {console.log('singleUser', singleUser)}
        {console.log('locationState', locationState)}
        <div className='card-body p-9'>
          {!loadingState ? (
            <>
              <div className='container'>
                <div className='row'>
                  <div className='me-7 mb-7 col-md-3'>
                    {singleUser.profile_image ? (
                      <div className='symbol symbol-150px  symbol-lg-160px symbol-fixed position-relative'>
                        <img
                          src={
                            singleUser.profile_image?.full_path +
                            'thumb/' +
                            singleUser.profile_image?.file_name
                          }
                          className='img-fluid w-150px h-150px'
                          alt='no image found'
                        />

                        {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
                      </div>
                    ) : (
                      <img src={logoImg} alt='not found' className='img-fluid w-150px h-150px' />
                    )}
                  </div>
                  <div className='col-md-8'>
                    <div className='row mt-4'>
                      <label className='col-md-4  mt-3 fw-bold text-muted'>Full Name</label>

                      <div className='col-md-8 mt-3 '>
                        <span className='fw-bolder fs-6 text-dark'>{`${
                          locationState.item.first_name ?? singleUser.first_name ?? '--'
                        } ${locationState.item.last_name ?? singleUser.last_name ?? '--'} `}</span>
                      </div>
                      <label className='col-md-4  mt-3 fw-bold text-muted'>Email</label>

                      <div className='col-md-8 mt-3 '>
                        <span className='fw-bolder fs-6 text-dark'>
                          {locationState.item.email ?? singleUser.email ?? '--'},
                        </span>
                      </div>
                      <label className=' col-md-4 mt-3  fw-bold text-muted'>Status</label>

                      <div className=' col-md-8 mt-3  fv-row'>
                        <span className='fw-bold fs-6'>
                          {locationState.item.status === 'active' ||
                          singleUser.status === 'active' ? (
                            <span className='badge badge-success'>Active</span>
                          ) : locationState.item.status === 'pending' ||
                            singleUser.status === 'pending' ? (
                            <span className='badge badge-warning me-1 mb-1'>Pending</span>
                          ) : locationState.item.status === 'disabled' ||
                            singleUser.status === 'disabled' ? (
                            <span className='badge badge-secondary me-1'>Inactive</span>
                          ) : locationState.item.status === 'deleted' ||
                            singleUser.status === 'deleted' ? (
                            <span className='badge badge-danger'>Delete</span>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {/* <h3 className='fw-bolder m-0'> */}

                {/* </h3> */}
              </div>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
      {openModel && (
        <Modal size='lg' isOpen={openModel} centered={true} toggle={null}>
          <ModalHeader toggle={() => setOpenModel(!openModel)}>
            <h5 className='modal-title'>Cards</h5>
          </ModalHeader>
          <ModalBody
            className='justify-content-center'
            style={{
              maxHeight: '80vh',
              overflowY: 'scroll',
            }}
          >
            <div className='table-responsive'>
              <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer '>
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    <th className='min-w-100px'>Card Banner</th>
                    <th className='text-center min-w-100px'>Title</th>
                    {/* <th className='text-center min-w-100px'>User Email</th> */}
                    <th className='text-center min-w-100px'>Status</th>
                    <th className='text-center min-w-100px'>Joined Date</th>
                    <th className='text-center min-w-100px'>Contacts</th>
                    <th className='text-center min-w-100px'>Card Detail</th>
                  </tr>
                </thead>
                {/* <div className='' style={{minHeight: '70px'}}> */}
                <tbody
                  className='text-gray-600 fw-bold '
                  style={{
                    height: '100px',
                    minHeight: '150px',
                    maxHeight: '60vh',
                    overflowY: 'scroll',
                  }}
                >
                  {/* {cardsFound ? ( */}
                  {singleUser.cards.length > 0
                    ? singleUser.cards.map((item, index) => (
                        <tr key={index}>
                          {item.banner ? (
                            <Link to={`/card/${item.id}`} state={{item: item}}>
                              <td className=' min-w-100px'>
                                {item.banner?.type == 'video' ? (
                                  item?.logo !== '' &&
                                  item?.logo !== undefined &&
                                  item?.logo !== null ? (
                                    <img
                                      src={item.logo?.full_path + 'thumb/' + item.logo?.file_name}
                                      alt=''
                                      className='img-fluid w-150px h-70px rounded'
                                    />
                                  ) : (
                                    <img
                                      src={dummyCard}
                                      alt=''
                                      className='img-fluid w-150px h-70px rounded'
                                    />
                                  )
                                ) : (
                                  <img
                                    src={item.banner?.full_path + item.banner?.file_name}
                                    alt=''
                                    className='img-fluid w-150px h-70px rounded'
                                  />
                                )}
                              </td>
                            </Link>
                          ) : (
                            <Link to={`/card/${item.id}`} state={{item: item}}>
                              <td className=' min-w-100px'>
                                <img
                                  src={dummyCard}
                                  alt='not found'
                                  className='img-fluid w-150px h-70px rounded'
                                />
                              </td>
                            </Link>
                          )}
                          {item.title === '' ? (
                            <td className=' min-w-100px text-center'>No Title</td>
                          ) : (
                            <td className=' min-w-100px text-center'>{item.title}</td>
                          )}

                          <td className='text-center min-w-100px'>
                            <div>
                              {item.status === 'active' ? (
                                <span className='badge badge-success'>Active</span>
                              ) : item.status === 'pending' ? (
                                <span className='badge badge-warning me-1 mb-1'>Pending</span>
                              ) : item.status === 'disabled' ? (
                                <span className='badge badge-secondary me-1'>Inactive</span>
                              ) : item.status === 'deleted' ? (
                                <span className='badge badge-danger'>Delete</span>
                              ) : null}
                            </div>
                          </td>
                          <td className='text-center min-w-100px'>
                            {dateFormateHandler(item.created_at)}
                          </td>
                          <td className='text-center min-w-100px'>
                            <Link to={`/cards/${item.id}/contacts`}>View</Link>
                          </td>
                          <td className='text-center min-w-100px'>
                            <Link to={`/card/${item.id}`} state={{item: item}}>
                              View
                            </Link>
                          </td>
                        </tr>
                      ))
                    : !loadingState && (
                        <div className='position-absolute ' style={{left: '40%', bottom: '40px'}}>
                          <p className='text-center min-w-100px '>Record not found</p>
                        </div>
                      )}
                </tbody>
                {/* </div> */}
                {/* {loadingState && (
                  <div className='position-absolute ' style={{left: '45%', bottom: '-40%'}}>
                    {loadingState && <Loading />}
                  </div>
                )} */}
              </table>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default ViewUser
