import React from 'react'
import Cards from './Cards'
import Users from './Users'

const Master = () => {
  return (
    <div className='row mt-10'>
      <div className='col-md-6'>
        <Users />
      </div>
      <div className='col-md-6'>
        <Cards />
      </div>
    </div>
  )
}

export default Master
