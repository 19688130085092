import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import logOut from '../../../../assets/side-icons/logOut.svg'
import {useNavigate} from 'react-router-dom'
const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {config} = useLayout()
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  // const logout = () => {
  //   document.location.reload()
  //   localStorage.clear()
  //   navigate('/auth/login')
  // }
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <a
            onClick={() => {
              localStorage.clear()
              //  navigate('/landing-screen')
              document.location.reload()
            }}
            className='menu-link px-2 py-3 text-dark'
            style={{backgroundColor: '#bae409', borderRadius: '5px', fontWeight: '500'}}
          >
            <img src={logOut} width={18} className='pe-1' alt='' />
            Sign Out
          </a>
          {/* <a onClick={logout} className='btn btn-danger'>
            Sign Out
          </a> */}

          {/* <h2>gsasfdkgsafdg</h2> */}
          {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='metronic' /> */}
        </div>

        {/* <HeaderUserMenu /> */}
        {/* end::Toggle */}
      </div>
      {/* end::User */}
      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Topbar}
