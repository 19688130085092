import React, {useState} from 'react'
import Axios from 'axios'
import {baseURL} from '../BaseURL'

export const loginUser = async (Email, Password) => {
  console.log('=====>', Email, Password)
  try {
    const response = await fetch(
      `${baseURL}/login`,

      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          email: Email,
          password: Password,
        }),
      }
    )
    console.log('resultttttttt', response)

    const result = await response.json()
    return result
  } catch (e) {
    throw e
  }
}
export const forgotPasswordRequest = async (email) => {
  try {
    const result = await Axios.post(`${baseURL}/forgot-password`, {
      email: email,

      headers: {
        'content-type': 'application/json',
      },
    })

    return result
  } catch (err) {
    const error = err.response.data.message
    throw error
  }
}

export const changePasswordRequest = async (email, code, password, confirmPassword) => {
  try {
    const result = await Axios.post(`${baseURL}/reset-password`, {
      token: code,
      password: password,
      password_confirmation: confirmPassword,
      email: email,
      headers: {
        'content-type': 'application/json',
      },
    })

    return result
  } catch (err) {
    const error = err.response.data.message
    throw error
  }
}

export const logOutUser = async (accessToken) => {
  try {
    const response = await fetch(`${baseURL}/logout`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + '  ' + accessToken,
      },
    })
    const result = await response.json()
    return result
  } catch (e) {
    throw e
  }
}
