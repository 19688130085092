import React, {useEffect, useState} from 'react'
import {KTCardBody, KTCard} from '../_metronic/helpers'
import logoImg from '../assets/iconLogo.png'
import {Link, useNavigate, useParams} from 'react-router-dom'
import './style.css'
import './ImageUpload.css'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'
import {getContacts} from '../components/services/admin-services/manage-contacts'
import {Loading} from './loading/Loading'
import Pagination from './common component/Pagination'
import {MdArrowBackIosNew} from 'react-icons/md'
const ViewContact = () => {
  const {contact_id} = useParams()
  const [usersFound, setUsersFound] = useState(true)
  const [loadingState, setLoadingState] = useState(false)

  const [isOpenFromDateModal, setIsOpenFromDateModal] = useState(false)
  const [isOpenToDateModal, setIsOpenToDateModal] = useState(false)
  const [date, setDate] = useState(new Date())
  const [date2, setDate2] = useState(new Date())
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  const [selectMultiValue, setSelectMultiValue] = useState([])
  // const [error, setError] = useState('')
  // const [throwError, setThrowError] = useState(true)

  const [contacts, setContacts] = useState([])
  const [lastPage, setLastPage] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const userData = localStorage.getItem('userData')
  const transformedData = JSON.parse(userData || '')
  const {accessToken} = transformedData
  let pageCount = lastPage
  const Navigate = useNavigate()
  useEffect(() => {
    getContactsInfo()
  }, [contact_id])

  const getContactsInfo = async (page = 1) => {
    try {
      setLoadingState(true)
      const result = await getContacts(accessToken, page, contact_id)
      console.log('contacts', result)
      if (result.status === true) {
        setLastPage(result.contacts.last_page)
        setCurrentPage(result.contacts.current_page)
        setLoadingState(false)
        setContacts(result.contacts.data)
        setUsersFound(true)
      }
    } catch (err) {
      setLoadingState(false)
      setUsersFound(false)
    }
  }

  const dateFormateHandler = (createdAt) => {
    let today = new Date(createdAt)
    let date = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      // second: '2-digit',
    }).format(today)
    return date
  }
  //   const applyFilters = async () => {
  //     setColorModel(false)
  //     let array2
  //     let array = []
  //     selectMultiValue.map((item, index) => {
  //       array.push(item.id)
  //     })
  //     if (selectMultiValue.length > 0) {
  //       array2 = JSON.stringify(array)
  //     } else {
  //       array2 = 'no Record Found'
  //     }
  //     console.log('sorry error here', array2)
  //     const result = await filterUsers(fromDate, toDate, array2, accessToken)
  //     console.log('filter result', result)
  //     try {
  //       if (result.status == true) {
  //         setLastPage(result.users.last_page)
  //         setCurrentPage(result.users.current_page)
  //         setLoadingState(false)
  //         setAllUsers(result.users.data)
  //         setUsersFound(true)
  //       } else {
  //         console.log('sorry error here')
  //       }
  //     } catch (err) {
  //       setLoadingState(false)
  //       setUsersFound(false)
  //     }
  //   }
  let pageDataArray = []
  for (let i = 1; i <= lastPage; i++) {
    pageDataArray.push(i)
  }

  const paginate = async (data) => {
    let page = data.selected + 1
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    await getContactsInfo(page)
  }
  const data = [
    {status: ' Active', id: 'active'},
    {status: ' Pending', id: 'pending'},
    {status: ' Disabled', id: 'disabled'},
    {status: 'Deleted', id: 'deleted'},
  ]

  const [options] = useState(data)

  const changeMultiSelectHandler = (e) => {
    setSelectMultiValue(e)
  }
  const onChangeFromDate = async (e) => {
    setDate(e)
    setFromDate(e.toLocaleDateString('zh-Hans-CN'))
  }

  const onChangeToDate = async (e) => {
    setDate2(e)
    setToDate(e.toLocaleDateString('zh-Hans-CN'))
  }
  return (
    <>
      <span
        className='px-2 py-1'
        style={{
          position: 'relative',
          bottom: '25px',
          cursor: 'pointer',
          left: '15px',
          backgroundColor: '#bae409',
          borderRadius: '5px',
        }}
        onClick={() => Navigate(-1)}
      >
        <MdArrowBackIosNew size={12} />
        Back
      </span>
      <KTCard className='mx-4 mb-20'>
        {/* Header End */}
        {/* Table Start */}
        <KTCardBody className='py-4 mb-4'>
          <h1>Contacts</h1>
          <div className='table-responsive' style={{minHeight: '170px'}}>
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer '>
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  <th className='min-w-100px'>User</th>
                  {/* <th className='text-center min-w-125px'>Card</th> */}
                  <th className='text-center min-w-100px'>Status</th>
                  <th className='text-center min-w-100px'>Joined Date</th>
                  {/* <th className='text-end min-w-100px'>Action</th> */}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold'>
                {contacts.length > 0
                  ? contacts.map((item, index) => (
                      <tr key={index}>
                        <td className=' min-w-100px'>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                              <a href='#'>
                                <div className='symbol-label'>
                                  {item.profile_image ? (
                                    <img
                                      src={
                                        item.profile_image?.full_path +
                                        'thumb/' +
                                        item.profile_image?.file_name
                                      }
                                      alt='not found'
                                      className=' w-100 h-100'
                                    />
                                  ) : (
                                    <img src={logoImg} alt='not found' className=' w-100 h-100' />
                                  )}
                                </div>
                              </a>
                            </div>
                            <div className='d-flex flex-column'>
                              <Link
                                to={`/manage-users/${item.id}`}
                                state={{item: item}}
                                className='text-gray-800 text-hover-primary mb-1'
                              >
                                {`${item.first_name} ${item.last_name}`}
                              </Link>
                              <span>{item.email}</span>
                            </div>
                          </div>
                        </td>
                        {/* 
                      <td className='text-center min-w-125px'>
                        {item.cards_count === 0 || item.cards_count == null ? (
                          <span
                            className='badge badge-circle badge-primary'
                            style={{cursor: 'pointer'}}
                          >
                            0
                          </span>
                        ) : (
                          <Link to={`/usercard/${item.id}`}>
                            <span
                              className='badge badge-circle badge-primary'
                              style={{cursor: 'pointer'}}
                            >
                              {item.cards_count ?? 0}
                            </span>
                          </Link>
                        )}
                      </td> */}

                        <td className='text-center min-w-100px'>
                          <div>
                            {item.status === 'active' ? (
                              <span className='badge badge-success'>Active</span>
                            ) : item.status === 'pending' ? (
                              <span className='badge badge-warning me-1 mb-1'>Pending</span>
                            ) : item.status === 'disabled' ? (
                              <span className='badge badge-secondary me-1'>Inactive</span>
                            ) : item.status === 'deleted' ? (
                              <span className='badge badge-danger'>Deleted</span>
                            ) : null}
                          </div>
                        </td>
                        <td className='text-center min-w-125px'>
                          {dateFormateHandler(item.created_at)}
                        </td>
                      </tr>
                    ))
                  : !loadingState && (
                      <div
                        className=''
                        style={{height: '200px', width: '95%', position: 'absolute'}}
                      >
                        <p className='text-center d-flex justify-content-center align-items-center py-15'>
                          Record not found
                        </p>
                      </div>
                    )}
              </tbody>
            </table>
            {loadingState && <Loading />}
          </div>
          {/* Pagination Start*/}
          {lastPage > 1 && (
            <div className='row  mt-3'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <ul className='pagination'>
                  {lastPage > 1 && <Pagination pageCount={pageCount} paginate={paginate} />}
                </ul>
              </div>
            </div>
          )}

          {/* Pagination End*/}
        </KTCardBody>
      </KTCard>
      {/* Table End */}

      <Modal
        size='md'
        isOpen={isOpenFromDateModal}
        centered={true}
        toggle={() => setIsOpenFromDateModal(!isOpenFromDateModal)}
      >
        <ModalHeader toggle={() => setIsOpenFromDateModal(!isOpenFromDateModal)}>
          Delete member in Board
        </ModalHeader>
        <ModalBody>
          <Calendar onChange={onChangeFromDate} value={date} />
        </ModalBody>
        <ModalFooter>
          <div className='text-end'>
            <Button color='secondary' onClick={() => setIsOpenFromDateModal(false)}>
              Cancel
            </Button>
          </div>
          <div className='text-end'>
            <Button
              type='button'
              color='primary'
              // onClick={() => setIsOpenFromDateModal(!isOpenFromDateModal)}

              onClick={() => setIsOpenFromDateModal(false)}
            >
              Delete member
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        size='md'
        isOpen={isOpenFromDateModal}
        centered={true}
        toggle={() => setIsOpenToDateModal(!isOpenToDateModal)}
      >
        <ModalHeader toggle={() => setIsOpenToDateModal(!isOpenToDateModal)}>
          Delete member in Board
        </ModalHeader>
        <ModalBody>
          <Calendar onChange={onChangeToDate} value={date2} />
        </ModalBody>
        <ModalFooter>
          <div className='text-end'>
            <Button color='secondary' onClick={() => setIsOpenToDateModal(false)}>
              Cancel
            </Button>
          </div>
          <div className='text-end'>
            <Button
              type='button'
              color='primary'
              // onClick={() => setIsOpenFromDateModal(!isOpenFromDateModal)}

              onClick={() => setIsOpenToDateModal(false)}
            >
              Delete member
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ViewContact
