import {baseURL} from '../../BaseURL'

export const createCard = async (
  accessToken,
  selectedUsers,
  cardBanner,
  cardSocials,
  company,
  department,
  firstName,
  headline,
  lastName,
  cardLogo,
  status,
  themeColor,
  themeStyle,
  title,
  accreditation,
  maidenName,
  middleName,
  preferredName,
  prefix,
  suffix,
  pronouns,
  cvFile,
  location,
  profile,
  relatedCard
) => {
  let formData = new FormData()
  if (cardBanner !== '') {
    formData.append('theme_image', cardBanner)
  }
  formData.append('card_items', JSON.stringify(cardSocials))
  formData.append('company', company)
  formData.append('user', selectedUsers)
  formData.append('department', department)
  formData.append('first_name', firstName)
  formData.append('headline', headline)
  formData.append('last_name', lastName)
  if (cardLogo !== '') {
    formData.append('logo', cardLogo)
  }
  if (profile !== '') {
    formData.append('banner', profile)
  }
  formData.append('status', status?.toLowerCase())
  formData.append('theme_color', themeColor)
  formData.append('theme_style', themeStyle)
  if (title !== '' && title !== undefined && title !== undefined) {
    formData.append('title', title)
  } else {
    formData.append('title', 'New Card')
  }
  formData.append('accreditation', accreditation)
  let cards = []
  relatedCard.map((item, index) => cards.push({name: item?.label, id: item?.value}))
  formData.append('related_card', JSON.stringify(cards))

  formData.append('maiden_name', maidenName)
  formData.append('middle_name', middleName)
  formData.append('preferred_name', preferredName)
  formData.append('prefix', prefix)
  formData.append('suffix', suffix)
  formData.append('pronouns', pronouns)
  if (cvFile !== '') {
    formData.append('cv_file', cvFile)
  }
  formData.append('locations', JSON.stringify(location))

  const response = await fetch(`${baseURL}/admin/cards`, {
    body: formData,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const result = await response.json()

  return result
}

export const updateCard = async (
  id,
  accessToken,
  theme,
  cardSocials,
  company,
  department,
  firstName,
  headline,
  lastName,
  showEditCardLogo,
  status,
  themeColor,
  themeStyle,
  title,
  accreditation,
  maidenName,
  middleName,
  preferredName,
  prefix,
  suffix,
  pronouns,
  showCvFile,
  location,
  selectedUsers,
  banner,
  imageDelete,
  relatedCard
) => {
  let formData = new FormData()
  if (theme !== '') {
    formData.append('theme_image', theme)
    console.log('showEdittheme', theme)
  }

  if (banner !== '') {
    formData.append('banner', banner)
  }
  if (imageDelete) {
    console.log('imageDelete', imageDelete)

    formData.append('theme_image_delete', imageDelete)
  }
  if (showEditCardLogo !== '') {
    formData.append('logo', showEditCardLogo)
  }
  formData.append('card_items', JSON.stringify(cardSocials))
  let cards = []
  relatedCard.map((item, index) => cards.push({name: item?.label, id: item?.value}))
  formData.append('related_card', JSON.stringify(cards))
  formData.append('company', company)
  // formData.append('user', selectedUsers)
  formData.append('department', department)
  formData.append('first_name', firstName)
  formData.append('headline', headline)
  formData.append('last_name', lastName)
  formData.append('status', status?.toLowerCase())
  formData.append('theme_color', themeColor)
  formData.append('theme_style', themeStyle)
  if (title !== '' && title !== undefined && title !== undefined) {
    formData.append('title', title)
  } else {
    formData.append('title', 'New Card')
  }
  formData.append('accreditation', accreditation)
  formData.append('maiden_name', maidenName)
  formData.append('middle_name', middleName)
  formData.append('preferred_name', preferredName)
  formData.append('prefix', prefix)
  formData.append('suffix', suffix)
  formData.append('pronouns', pronouns)
  if (showCvFile !== '') {
    formData.append('cv_file', showCvFile)
  }
  formData.append('locations', JSON.stringify(location))
  console.log('selectedUsers', selectedUsers)
  const response = await fetch(`${baseURL}/admin/cards/${id}`, {
    body: formData,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer' + ' ' + accessToken,
    },
  })
  const result = await response.json()
  return result
}

export const deleteCard = async (accessToken, id) => {
  const response = await fetch(`${baseURL}/admin/cards/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })

  const result = await response.json()

  return result
}

export const getCards = async (accessToken, page, search) => {
  const response = await fetch(`${baseURL}/admin/cards/search?page=${page}`, {
    body: JSON.stringify({
      search: search,
    }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  const result = await response.json()
  return result
}
export const getAttachedCard = async (accessToken, id) => {
  const response = await fetch(`${baseURL}/admin/cards/get-attachable-cards/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  const result = await response.json()
  return result
}
export const getUpdateAttachedCard = async (accessToken, userId, id) => {
  const response = await fetch(`${baseURL}/admin/cards/get-attachable-cards/${userId}/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  console.log('user id', userId)
  console.log('id', id)
  const result = await response.json()
  return result
}
export const getUsers = async (accessToken, page) => {
  const response = await fetch(`${baseURL}/users`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  const result = await response.json()

  return result
}
export const getSingleCard = async (id, accessToken) => {
  const response = await fetch(`${baseURL}/admin/cards/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  const result = await response.json()

  return result
}

export const filterCards = async (
  search,
  fromDate,
  toDate,
  status,
  city,
  province,
  country,
  accessToken
) => {
  const response = await fetch(`${baseURL}/admin/cards/search`, {
    body: JSON.stringify({
      search: search,
      from_date: fromDate,
      to_date: toDate,
      status: status,
      city: city,
      province: province,
      country: country,
    }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  const result = await response.json()
  return result
}
