/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import userIcon from '../../../../assets/side-icons/user-icon.svg'
import contact from '../../../../assets/side-icons/contact.svg'
import card from '../../../../assets/side-icons/cards.svg'
import userIconBlack from '../../../../assets/side-icons/user-black.svg'
import contactBlack from '../../../../assets/side-icons/contact-black.svg'
import cardBlack from '../../../../assets/side-icons/card-black.svg'
import {Link, useLocation} from 'react-router-dom'
import clsx from 'clsx'
export function AsideMenuMain() {
  // const intl = useIntl()
  const location = useLocation()
  const {pathname} = location
  console.log('pathname', pathname)
  return (
    <>
      <div className='menu-item'>
        <Link className={clsx('menu-link without-sub')} to='/manage-users'>
          <span className='menu-icon'>
            <img
              className='svg-icon-2'
              src={pathname == '/manage-users' ? userIcon : userIconBlack}
              alt=''
              width={20}
            />
            {/* <KTSVG
              path={pathname == '/manage-users' ? userIcon : userIconBlack}
              className='svg-icon-2'
            /> */}
          </span>

          {/* <i className={clsx('bi fs-3', 'bi-app-indicator')}></i> */}
          <span className={`menu-title ${pathname == '/manage-users' ? 'text-white' : ''}`}>
            {'User Management'}
          </span>
        </Link>
        {/* {children} */}
      </div>
      <div className='menu-item'>
        <Link className={clsx('menu-link without-sub')} to='/manage-cards'>
          <span className='menu-icon'>
            <img
              className='svg-icon-2'
              src={pathname == '/manage-cards' ? card : cardBlack}
              alt=''
              width={20}
            />
            {/* <KTSVG path={pathname == '/manage-cards' ? card : cardBlack} className='svg-icon-2' /> */}
          </span>

          {/* <i className={clsx('bi fs-3', 'bi-app-indicator')}></i> */}
          <span className={`menu-title ${pathname == '/manage-cards' ? 'text-white' : ''}`}>
            {'Manage User Cards'}
          </span>
        </Link>
        {/* {children} */}
      </div>
      <div className='menu-item'>
        <Link className={clsx('menu-link without-sub')} to='/manage-contacts'>
          <span className='menu-icon'>
            <img
              className='svg-icon-2'
              src={pathname == '/manage-contacts' ? contact : contactBlack}
              alt=''
              width={20}
            />
            {/* <KTSVG
              path={pathname == '/manage-contacts' ? contact : contactBlack}
              className='svg-icon-2'
            /> */}
          </span>

          <span className={`menu-title ${pathname == '/manage-contacts' ? 'text-white' : ''}`}>
            {'Manage Contacts'}
          </span>
        </Link>
      </div>

      {/* <AsideMenuItem
        to='/manage-users'
        icon={pathname == '/manage-users' ? userIcon : userIconBlack}
        title={intl.formatMessage({id: 'User Management'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/manage-cards'
        icon={pathname == '/manage-card' ? card : cardBlack}
        title={intl.formatMessage({id: 'Manage User Cards'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/manage-contacts'
        icon={pathname == '/manage-contacts' ? contact : contactBlack}
        title={intl.formatMessage({id: 'Manage Contacts'})}
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/manage-packages'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'Manage Packages' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/manage-permissions'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'Manage Permissions' })}
        fontIcon='bi-app-indicator'
      /> */}
    </>
  )
}
