import React, {useState, useEffect} from 'react'

import {KTSVG} from '../_metronic/helpers'
// import {KTSVG} from '../../../helpers'
import {getDashboardRecord} from '../components/services/admin-services/dashboard'
import {FaUsers} from 'react-icons/fa'
import Master from './dashboard-tables/Master'
import {Loading} from './loading/Loading'

const DashBoard = () => {
  ///////////////////////////////////
  //
  /////////////////////////////////////
  const [totalCards, setTotalCards] = useState('')
  const [activeCards, setActiveCards] = useState('')
  const [deletedCards, setDeletedCards] = useState('')
  const [pendingCards, setPendingCards] = useState('')
  const [inActiveCards, setInActiveCards] = useState('')
  //////////////////////////////////
  ///////////////////////////////////
  const [totalUsers, setTotalUsers] = useState('')
  const [activeUsers, setActiveUsers] = useState('')
  const [deletedUsers, setDeletedUsers] = useState('')
  const [pendingUsers, setPendingUsers] = useState('')
  const [inActiveUsers, setInActiveUsers] = useState('')
  const [loading, setLoading] = useState(true)
  //////////////////////////////////
  const userData = localStorage.getItem('userData')
  const transformedData = JSON.parse(userData || '')
  const {accessToken} = transformedData
  useEffect(() => {
    getDashboardData()
  }, [])

  const getDashboardData = async () => {
    setLoading(true)
    try {
      const result = await getDashboardRecord(accessToken)
      if (result.status == true) {
        setTotalCards(result.cards.total)
        setActiveCards(result.cards.active)
        setDeletedCards(result.cards.deleted)
        setPendingCards(result.cards.pending)
        setInActiveCards(result.cards.disabled)
        setTotalUsers(result.users.total)
        setActiveUsers(result.users.active)
        setDeletedUsers(result.users.deleted)
        setPendingUsers(result.users.pending)
        setInActiveUsers(result.users.disabled)
        setLoading(false)
      } else {
        console.log('sorry error here')
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      <div className='card'>
        <div className='card-header border-0 py-5 pb-20' style={{backgroundColor: ' #BAE409'}}>
          <h3 className='card-title fw-bolder text-white '>TapShareTech</h3>
          <div className='card-toolbar'></div>
        </div>

        <div className='card-body p-0'>
          {!loading ? (
            <div className='card-p mt-n20 position-relative'>
              <div className='row g-0'>
                <div className='col bg-light-primary px-6 py-8 rounded-2 me-3 mb-5'>
                  <FaUsers size={30} className='text-primary d-block my-2' />
                  <div className='text-primary fw-bold fs-6'>Total Users</div>
                  <div>
                    <h1 className='text-primary fw-bold fs-2'>{totalUsers}</h1>
                  </div>
                </div>
                <div className='col bg-light-success px-6 py-8 rounded-2 mb-5'>
                  <KTSVG
                    path='/media/icons/duotune/abstract/abs027.svg'
                    className='svg-icon-3x svg-icon-success d-block my-2'
                  />
                  <div className='text-success fw-bold fs-6 mt-2'>Total Cards</div>
                  <h1 className='text-success fw-bold fs-2'>{totalCards}</h1>
                </div>
              </div>
              <div className='row g-0'>
                <div className='col bg-light-primary px-6 py-8 rounded-2 mb-5 me-3 '>
                  <FaUsers size={30} className='text-primary d-block my-2' />
                  <div className='text-primary fw-bold fs-6'>Active Users</div>
                  <h1 className='text-primary fw-bold fs-2'>{activeUsers} </h1>
                </div>
                <div className='col bg-light-dark px-6 py-8 rounded-2 mb-5'>
                  <FaUsers size={30} className='text-dark d-block my-2' />
                  <div className='text-dark fw-bold fs-6 mt-2'>Inactive Users</div>
                  <h1 className='text-dark fw-bold fs-2'>{inActiveUsers}</h1>
                </div>
                <div className='col bg-light-warning px-6 py-8 rounded-2 mx-3 mb-5'>
                  <FaUsers size={30} className='text-warning d-block my-2' />
                  <div className='text-warning fw-bold fs-6'>Pending Users</div>
                  <h1 className='text-warning fw-bold fs-2'>{pendingUsers} </h1>
                </div>
                <div className='col bg-light-danger px-6 py-8 rounded-2  mb-5'>
                  <FaUsers size={30} className='text-danger d-block my-2' />
                  <div className='text-danger fw-bold fs-6'>Deleted Users</div>
                  <h1 className='text-danger fw-bold fs-2'>{deletedUsers} </h1>
                </div>
              </div>

              <div className='row g-0'>
                <div className='col bg-light-success px-6 py-8 rounded-2 mb-5 me-3'>
                  <KTSVG
                    path='/media/icons/duotune/abstract/abs027.svg'
                    className='svg-icon-3x svg-icon-success d-block my-2'
                  />
                  <div className='text-success fw-bold fs-6 mt-2'>Active Cards</div>
                  <h1 className='text-success fw-bold fs-2'>{activeCards}</h1>
                </div>
                <div className='col bg-light-dark px-6 py-8 rounded-2 mb-5'>
                  <KTSVG
                    path='/media/icons/duotune/abstract/abs027.svg'
                    className='svg-icon-3x svg-icon-dark d-block my-2'
                  />
                  <div className='text-dark fw-bold fs-6 mt-2'>Inactive Cards</div>
                  <h1 className='text-dark fw-bold fs-2'>{inActiveCards}</h1>
                </div>
                <div className='col bg-light-warning px-6 py-8 rounded-2 mb-5 mx-3'>
                  <KTSVG
                    path='/media/icons/duotune/abstract/abs027.svg'
                    className='svg-icon-3x svg-icon-warning d-block my-2'
                  />
                  <div className='text-warning fw-bold fs-6 mt-2'>Pending Cards</div>
                  <h1 className='text-warning fw-bold fs-2'>{pendingCards} </h1>
                </div>

                <div className='col bg-light-danger px-6 py-8 rounded-2 mb-5'>
                  <KTSVG
                    path='/media/icons/duotune/abstract/abs027.svg'
                    className='svg-icon-3x svg-icon-danger d-block my-2'
                  />
                  <div className='text-danger fw-bold fs-6 mt-2'>Deleted Cards</div>
                  <h1 className='text-danger fw-bold fs-2'>{deletedCards}</h1>
                </div>
              </div>
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
      <Master />
    </>
  )
}

export default DashBoard
