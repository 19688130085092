const Loading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  return (
    <div
      className='d-flex justify-content-center text-center align-items-center'
      style={{minHeight: '75vh', width: '100%'}}
    >
      <div
        class='spinner-border'
        role='status'
        style={{color: '#050505', width: '4rem', height: '4rem'}}
      >
        <span class='visually-hidden'>Loading...</span>
      </div>
    </div>
  )
}
export {Loading}
