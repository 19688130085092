import {baseURL} from '../../BaseURL'
export const getContacts = async (accessToken, page, id) => {
  console.log('idd', id)
  //   const response = await fetch(`${baseURL}/users/search?page=${page}`, {
  const response = await fetch(`${baseURL}/admin/cards/${id}/contacts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  const result = await response.json()
  // console.log(result)
  return result
}

export const filterUsers = async (fromDate, toDate, status, accessToken) => {
  console.log(fromDate)
  console.log(toDate)
  const response = await fetch(`${baseURL}/users/search`, {
    body: JSON.stringify({
      from_date: fromDate,
      to_date: toDate,
      status: status,
    }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer' + '  ' + accessToken,
    },
  })
  const result = await response.json()
  return result
}
