import React, {useState, useEffect} from 'react'
import {KTCardBody, KTCard} from '../../_metronic/helpers'
import RoundDummyCard from '../../assets/roundDummyImage.JPG'

import {Link, useNavigate, useParams} from 'react-router-dom'
import dummyCard from '../../assets/dammyCard.PNG'
import newDummyCard from '../../assets/newDummyCard.JPG'
import {getSingleUser} from '../services/admin-services/manage-users'
import {Loading} from '../loading/Loading'
import {MdArrowBackIosNew} from 'react-icons/md'
const UserCards = () => {
  const userData = localStorage.getItem('userData')
  const transformedData = JSON.parse(userData || '')
  const {accessToken} = transformedData
  const [loadingState, setLoadingState] = useState(false)
  const [userCards, setUserCards] = useState([])
  const {id} = useParams()
  const Navigate = useNavigate()
  useEffect(() => {
    async function getCards() {
      try {
        const result = await getSingleUser(id, accessToken)
        setLoadingState(true)
        if (result.status === true) {
          setLoadingState(false)
          setUserCards(result.user)
        }
      } catch (err) {
        setLoadingState(false)
        console.log('user err', err)
        // setErrorModelText(err.response.data.message)
        // setErrorModel(true);
      }
    }
    getCards()
  }, [id])
  console.log('user card', userCards)
  return (
    <>
      <span
        className='px-2 py-1'
        style={{
          position: 'relative',
          bottom: '25px',
          cursor: 'pointer',
          backgroundColor: '#bae409',
          borderRadius: '5px',
        }}
        onClick={() => Navigate(-1)}
      >
        <MdArrowBackIosNew size={12} />
        Back
      </span>
      {userCards?.cards?.length > 0 ? (
        <>
          {userCards.cards?.map((item, index) => (
            <KTCard className=' mb-10 py-5'>
              {/* <Loading /> */}
              <KTCardBody className='py-4 mb-4'>
                <div key={index} className='row text-sm-center'>
                  <div className='row pb-9'>
                    <div className='col-9'>
                      <h1 className='mb-8 text-truncate text-start'>{item.title ?? ''}</h1>
                    </div>
                    <div className='col-3'>
                      <Link
                        to={`/card/${item.id}`}
                        className='text-end btn'
                        style={{backgroundColor: '#bae409', position: 'relative', left: '50px'}}
                      >
                        View Card
                      </Link>
                    </div>
                  </div>

                  <div className='col-md-4 col-12'>
                    <h5>Card Logo</h5>
                    <div className=' min-w-100px my-3'>
                      {item.logo !== '' && item.logo !== undefined && item.logo !== null ? (
                        <img
                          src={item.logo?.full_path + 'thumb/' + item.logo?.file_name}
                          alt=''
                          className='img-fluid '
                          style={{borderRadius: '50%', height: '120px', width: '120px'}}
                        />
                      ) : (
                        <img
                          src={RoundDummyCard}
                          alt=''
                          className='img-fluid    '
                          style={{borderRadius: '50%', height: '120px', width: '120px'}}
                        />
                      )}
                    </div>
                  </div>
                  <div className='col-4'>
                    <h5>Card Banner</h5>
                    <div className=' min-w-100px my-3'>
                      {item.banner?.type == 'video' ? (
                        <video
                          className='img-fluid  h-150px rounded'
                          width='125px'
                          height='100%'
                          controls
                          src={
                            item.banner?.full_path + item.banner?.file_name
                              ? item.banner?.full_path + item.banner?.file_name
                              : newDummyCard
                          }
                          style={{width: '200px'}}
                        />
                      ) : (
                        <img
                          src={
                            item.banner?.full_path + item.banner?.file_name
                              ? item.banner?.full_path + 'thumb/' + item.banner?.file_name
                              : newDummyCard
                          }
                          alt=''
                          className='img-fluid rounded  h-100px mt-4'
                          style={{width: '200px'}}
                        />
                      )}

                      {/* {item.banner !== '' && item.banner !== undefined && item.banner !== null ? (
                    <img
                      src={item.banner?.full_path + 'thumb/' + item.banner?.file_name}
                      alt=''
                      className='img-fluid rounded  h-150px'
                    />
                  ) : (
                    <img src={dummyCard} alt='' className='img-fluid w-75 rounded  h-150px' />
                  )} */}
                    </div>
                  </div>

                  <div className='col-3'>
                    <h5 className=''>Card QR code</h5>
                    <div className=' min-w-100px my-3'>
                      {item.qr_code !== '' &&
                      item.qr_code !== undefined &&
                      item.qr_code !== null ? (
                        <img src={item.qr_code} alt='' className='img-fluid  rounded  h-150px' />
                      ) : (
                        <img
                          src={dummyCard}
                          alt=''
                          className='img-fluid  rounded  h-150px'
                          style={{width: '234px'}}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className='row'>
              <div className='mt-8 col-12'>
                <tr>
                  <td className='fs-3 min-w-150px fw-bold'>Card Status</td>
                  <td className='fs-6 min-w-100px'>
                    {item.status === 'active' ? (
                      <span className='badge badge-success'>Active</span>
                    ) : item.status === 'pending' ? (
                      <span className='badge badge-warning me-1 mb-1'>Pending</span>
                    ) : item.status === 'disabled' ? (
                      <span className='badge badge-secondary me-1'>Inactive</span>
                    ) : item.status === 'disabled' ? (
                      <span className='badge badge-danger'>Deleted</span>
                    ) : null}
                  </td>
                </tr>
              </div>
              <div></div>
            </div> */}
              </KTCardBody>
            </KTCard>
          ))}
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}

export default UserCards
